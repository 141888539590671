<!-- <div class="smart-set-intro">
    <div class="title">
        Smart Sets
    </div>
    <div class="smart-set-content">
        <ul class="list">
            <li class="smart-set-content-item">Save the search results with Smart Sets</li>
            <li class="smart-set-content-item">Drag and drop the fetched results to create a Smart Set</li>
            <li class="smart-set-content-item">The smart set will always stay in sync with its external source</li>
            <li class="smart-set-content-item">Rename the created smart set through the panel or on the canvas</li>
        </ul>
    </div>
</div> -->

<div class="pointer" [ngClass]="{'dragging': dragging }" draggable="true" (dragstart)="handleSmartSetDragStart( $event )" (dragend)="handleSmartSetDragEnd( $event )">
    <div tooltip placement="right" class="search-result-menu">
        <div class="line">
            <div class="moveble-image">
                <svg class="nu-icon nu-ic-movable">
                    <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-movable"></use>
                </svg>
            </div>
            <div class="medium-menu-text no-left-margin">
                Filtered Results ( {{resultCount}} )
            </div>
        </div>
        <div class="line">
            <div class="escalation-image" #floatingTooltip floatingContainer placement="right" openOnHover="false">
                <!-- <svg class="nu-icon nu-ic-exclamation">
                    <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-exclamation"></use>
                </svg> -->
                <span class="floating-content">
                    <div class="image-cover">
                        <img class="smart-into-image" src="./assets/tooltip-tour/tooltip-smart-sets-filter.gif" alt="smartset">
                        <svg (click)="closeTooltip()" class="nu-icon nu-ic-close-btn">
                            <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-close"></use>
                        </svg>
                    </div>
                    <div class="smart-set-intro">
                        <div class="title fx-margin-bottom-5">
                            Visualize your Data
                        </div>
                        <div class="smart-set-content fx-margin-bottom-5">
                            <span>You can drag and drop this entire set or individual items to the canvas to visualize your data</span>
                        </div>
                        <div class="fx-margin-bottom-5 fx-margin-top-5">
                            <div class="form-inline row-item">
                                <div class="input">
                                    <input type="checkbox" class="form-check-input" [checked]="checked | async" (click)="checked.next(!checked.value)" />
                                    <label class="form-check-label">
                                        <div class="library-name">Don’t show this again</div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </span>
            </div>
            <!-- <div tooltip placement="top"> -->
                <svg *ngIf="(searchResult | async).length > 0" (click)="createSavedSet()" class="nu-icon nu-ic-save" id="edata-saved-dataset-btn">
                    <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-save"></use>
                </svg>
                <!-- <span class="tooltip-content">Set as a Set</span>
            </div> -->
            <svg (click)="back()" class="nu-icon nu-ic-close">
                <use xlink:href="./assets/icons/symbol-defs.svg#nu-ic-close"></use>
            </svg>
        </div>
        <span class="tooltip-content">Drag and drop results onto the workspace to create a saved set</span>
    </div>
    <div class="result-contianer">
        <div #searchResultContainer></div>
    </div>
</div>